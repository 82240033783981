// src/config-global.ts
export const CONFIG = {
  site: {
    name: "Avalanche",
    basePath: import.meta.env.VITE_BASE_PATH ?? "",
    serverUrl: import.meta.env.VITE_SERVER_URL ?? "",
    assetURL: "https://your.cdn.url", // Assurez-vous que cette ligne existe
    version: "1.0.0", // Ajoutez cette ligne
  },
  auth: {
    method: "jwt",
    redirectPath: "/auth/sign-in",
  },
  mapbox: {
    apiKey:
      import.meta.env.VITE_MAPBOX_API_KEY ?? "your-default-mapbox-api-key", // Ajoutez cette ligne
  },
};

console.log("CONFIG.site.basePath:", CONFIG.site.basePath);
console.log("CONFIG.site.serverUrl:", CONFIG.site.serverUrl);
console.log("CONFIG.auth.redirectPath:", CONFIG.auth.redirectPath);
console.log("CONFIG.mapbox.apiKey:", CONFIG.mapbox.apiKey); // Ajoutez cette ligne pour vérifier la clé API
