import type {IconButtonProps} from '@mui/material/IconButton';
import {useState, useCallback} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import log from 'loglevel';

import {paths} from 'src/routes/paths';
import {useRouter, usePathname} from 'src/routes/hooks';
import {varAlpha} from 'src/theme/styles';

import {Label} from 'src/components/label';
import {Iconify} from 'src/components/iconify';
import {Scrollbar} from 'src/components/scrollbar';
import {AnimateAvatar} from 'src/components/animate';

import {UpgradeBlock} from './nav-upgrade';
import {AccountButton} from './account-button';
import {SignOutButton} from './sign-out-button';

import {useAuthContext} from 'src/auth/hooks';
import axiosInstance from "../../utils/axios"; // Utiliser le contexte d'authentification

// ----------------------------------------------------------------------

export type AccountDrawerProps = IconButtonProps & {
    data?: {
        label: string;
        href: string;
        icon?: React.ReactNode;
        info?: React.ReactNode;
    }[];
};

export function AccountDrawer({data = [], sx, ...other}: AccountDrawerProps) {
    const theme = useTheme();
    const router = useRouter();
    const pathname = usePathname();
    const {user, logout} = useAuthContext(); // Utiliser le contexte d'authentification
    const [open, setOpen] = useState(false);

    // log.debug('Rendering AccountDrawer with user:', user);

    const handleOpenDrawer = useCallback(() => {
        setOpen(true);
    }, []);

    const handleCloseDrawer = useCallback(() => {
        setOpen(false);
    }, []);

    const handleClickItem = useCallback(
        (path: string) => {
            handleCloseDrawer();
            router.push(path);
        },
        [handleCloseDrawer, router]
    );

    const handleLogout = async () => {
        await axiosInstance.post("/v1/auth/logout");
        logout();
        router.push(paths.auth.jwt.signIn); // Rediriger vers la page de connexion après déconnexion
    };

    const renderAvatar = (
        <AnimateAvatar
            width={96}
            slotProps={{
                avatar: {src: user?.avatar_url, alt: `${user?.first_name} ${user?.last_name}`},
                overlay: {
                    border: 2,
                    spacing: 3,
                    color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
                },
            }}
        >
            {user?.first_name?.charAt(0).toUpperCase()}
        </AnimateAvatar>
    );

    return (
        <>
            <AccountButton
                open={open}
                onClick={handleOpenDrawer}
                photoURL={user?.avatar_url || ''}
                displayName={`${user?.first_name} ${user?.last_name}`}
                sx={sx}
                {...other}
            />

            <Drawer
                open={open}
                onClose={handleCloseDrawer}
                anchor="right"
                slotProps={{backdrop: {invisible: true}}}
                PaperProps={{sx: {width: 320}}}
            >
                <IconButton
                    onClick={handleCloseDrawer}
                    sx={{top: 12, left: 12, zIndex: 9, position: 'absolute'}}
                >
                    <Iconify icon="mingcute:close-line"/>
                </IconButton>

                <Scrollbar>
                    <Stack alignItems="center" sx={{pt: 8}}>
                        {renderAvatar}

                        <Typography variant="subtitle1" noWrap sx={{mt: 2}}>
                            {`${user?.first_name} ${user?.last_name}`}
                        </Typography>

                        <Typography variant="body2" sx={{color: 'text.secondary', mt: 0.5}} noWrap>
                            {user?.company_name}
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="center" sx={{p: 3}}>
                        {/* Options pour changer de compte ou ajouter un compte */}
                    </Stack>

                    <Stack
                        sx={{
                            py: 3,
                            px: 2.5,
                            borderTop: `dashed 1px ${theme.vars.palette.divider}`,
                            borderBottom: `dashed 1px ${theme.vars.palette.divider}`,
                        }}
                    >
                        {data.map((option) => {
                            const rootLabel = pathname.includes('/dashboard') ? 'Home' : 'Dashboard';
                            const rootHref = pathname.includes('/dashboard') ? '/' : paths.dashboard.root;

                            return (
                                <MenuItem
                                    key={option.label}
                                    onClick={() => handleClickItem(option.label === 'Home' ? rootHref : option.href)}
                                    sx={{
                                        py: 1,
                                        color: 'text.secondary',
                                        '& svg': {width: 24, height: 24},
                                        '&:hover': {color: 'text.primary'},
                                    }}
                                >
                                    {option.icon}

                                    <Box component="span" sx={{ml: 2}}>
                                        {option.label === 'Home' ? rootLabel : option.label}
                                    </Box>

                                    {option.info && (
                                        <Label color="error" sx={{ml: 1}}>
                                            {option.info}
                                        </Label>
                                    )}
                                </MenuItem>
                            );
                        })}
                    </Stack>

                    <Box sx={{px: 2.5, py: 3}}>
                        <UpgradeBlock/>
                    </Box>
                </Scrollbar>

                <Box sx={{p: 2.5}}>
                    <SignOutButton onClick={handleLogout}/>
                </Box>
            </Drawer>
        </>
    );
}
