import {UserType} from "../../types";
import {saveUserToLocalStorage} from "./storage";

// ----------------------------------------------------------------------

export function setUser(user: UserType) {
  try {
    saveUserToLocalStorage(user)
  } catch (error) {
    console.error('Error during set user:', error);
    throw error;
  }
}
