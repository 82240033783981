import { useState, useEffect, useCallback } from "react";
import { paths } from "src/routes/paths";
import { useRouter, usePathname, useSearchParams } from "src/routes/hooks";
import { CONFIG } from "src/config-global";
import { SplashScreen } from "src/components/loading-screen";
import { useAuthContext } from "../hooks";
import log from "loglevel";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthGuard({ children }: Props) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { authenticated, loading, user } = useAuthContext();
  const [isChecking, setIsChecking] = useState<boolean>(true);

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);
      return params.toString();
    },
    [searchParams]
  );

  const checkPermissions = async (): Promise<void> => {
    log.debug("Checking permissions, loading:", loading, "authenticated:", authenticated);
    if (loading) {
      return;
    }
    if (!authenticated) {
      const { method } = CONFIG.auth;
      const signInPath = {
        jwt: paths.auth.jwt.signIn,
      }[method];

      const href = `${signInPath}?${createQueryString("returnTo", pathname)}`;
      log.debug("User not authenticated, redirecting to:", href);
      router.replace(href);
      return;
    }

    if (user && !user.email_verified) {
      router.replace(paths.auth.jwt.signIn);
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
  }, [authenticated, loading, user]);

  if (isChecking) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
