import log from "loglevel";
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { ProgressBar } from "src/components/progress-bar";
import { SettingsDrawer } from "src/components/settings";
import "src/global.css";
import { ThemeProvider } from "src/theme/theme-provider";
import { AuthProvider } from "./auth/context/auth-context";
import { MicrosoftOAuthProvider } from "./auth/context/microsoftContext";
import { MotionLazy } from "./components/animate/motion-lazy";
import { SplashScreen } from "./components/loading-screen";
import { SettingsProvider } from "./components/settings/context";
import type { SettingsState } from "./components/settings/types";
import { LocalizationProvider } from "./locales";
import { Router } from "./routes/sections"; // Assurez-vous que cette importation est correcte

const initialSettings: SettingsState = {
  fontFamily: "Roboto, sans-serif",
  compactLayout: true,
  direction: "ltr",
  colorScheme: "light",
  contrast: "default",
  navColor: "integrate",
  navLayout: "vertical",
  primaryColor: "default",
};

log.debug("Initial settings:", initialSettings);

function App() {
  return (
    <AuthProvider>
      <MicrosoftOAuthProvider>
        <SettingsProvider settings={initialSettings}>
          <ThemeProvider>
            <MotionLazy>
              <ProgressBar />
              <HelmetProvider>
                <LocalizationProvider>
                  <Suspense fallback={<SplashScreen />}>
                    <Router />
                    <SettingsDrawer />
                  </Suspense>
                </LocalizationProvider>
              </HelmetProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </MicrosoftOAuthProvider>
    </AuthProvider>
  );
}

export default App;
