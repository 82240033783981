import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import log from "loglevel";
import { setUser} from "./user/utils";
import type { AuthContextValue, UserType } from "src/auth/types";
import {getUserFromLocalStorage, USER_STORAGE_KEY} from "./user/storage";

// ----------------------------------------------------------------------

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

const AuthConsumer = AuthContext.Consumer;

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setAuthUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    log.debug("AuthProvider mounted");
    const userData = getUserFromLocalStorage()
      setUser(userData);
      setAuthUser(userData);
    setLoading(false);
  }, []);

  const login = (userData: UserType) => {
    if (userData) {
        setUser(userData);
        setAuthUser(userData);
      setUser(userData);
    } else {
      log.error("userData is null");
    }
  };

  const logout = () => {
    log.debug("User logging out");
    setUser(null);
    setAuthUser(null);
    localStorage.removeItem(USER_STORAGE_KEY);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        authenticated: !!user,
        unauthenticated: !user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthContext, AuthConsumer, AuthProvider, useAuth };
