import log from "loglevel";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import type { MicrosoftOAuthContextValue } from "src/auth/types";
import { CONFIG } from "src/config-global";

// ----------------------------------------------------------------------

const MicrosoftOAuthContext = createContext<
  MicrosoftOAuthContextValue | undefined
>(undefined);

const MicrosoftOAuthConsumer = MicrosoftOAuthContext.Consumer;

const MicrosoftOAuthProvider = ({ children }: { children: ReactNode }) => {
  const [isSync, setIsSynch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    log.debug("MicrosoftOAuthProvider mounted");
    // ToDo Inital value
  }, []);

  const auth = () => {
    try {
      setLoading(true);
      window.location.href = `${CONFIG.site.serverUrl}/v1/oauth2`;
    } catch (error) {
      console.error("Failed to initiate Outlook sync:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <MicrosoftOAuthContext.Provider
      value={{
        auth,
        loading,
        isSync,
      }}
    >
      {children}
    </MicrosoftOAuthContext.Provider>
  );
};

const useMicrosoftOAuth = () => {
  const context = useContext(MicrosoftOAuthContext);
  if (context === undefined) {
    throw new Error(
      "useMicrosoftOAuth must be used within an MicrosoftOAuthProvider"
    );
  }
  return context;
};

export {
  MicrosoftOAuthConsumer,
  MicrosoftOAuthContext,
  MicrosoftOAuthProvider,
  useMicrosoftOAuth,
};
