import { _id, _postTitles } from "src/_mock/assets";
import { paramCase } from "src/utils/change-case";

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];
const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const paths = {
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  faqs: "/faqs",
  page403: "/error/403",
  page404: "/error/404",
  page500: "/error/500",
  components: "/components",
  docs: "https://docs.minimals.cc",
  changelog: "https://docs.minimals.cc/changelog",
  zoneStore: "https://mui.com/store/items/zone-landing-page/",
  minimalStore: "https://mui.com/store/items/minimal-dashboard/",
  freeUI: "https://mui.com/store/items/minimal-dashboard-free/",
  figma:
    "https://www.figma.com/design/cAPz4pYPtQEXivqe11EcDE/%5BPreview%5D-Minimal-Web.v6.0.0",
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: { details: `/product/${MOCK_ID}` },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: { details: `/post/${paramCase(MOCK_TITLE)}` },
  },

  // AUTH
  auth: {
    oauthMicrosoft: `${ROOTS.AUTH}/oauth/microsoft`,
    jwt: {
      signIn: `${ROOTS.AUTH}/sign-in`,
      signUp: `${ROOTS.AUTH}/sign-up`,
      resetPassword: `${ROOTS.AUTH}/reset-password`,
      updatePassword: `${ROOTS.AUTH}/update-password`,
      verify: `${ROOTS.AUTH}/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    blank: `${ROOTS.DASHBOARD}/blank`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      course: `${ROOTS.DASHBOARD}/course`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    contact: {
      root: `${ROOTS.DASHBOARD}/contacts`,
      new: `${ROOTS.DASHBOARD}/contacts/new`,
      list: `${ROOTS.DASHBOARD}/contacts/list`,
    },
    campaign: {
      root: `${ROOTS.DASHBOARD}/campaign`,
      new: `${ROOTS.DASHBOARD}/campaign/new`,
      list: `${ROOTS.DASHBOARD}/campaign/list`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/campaign/${id}/edit`,
    },
    callbot: {
      root: `${ROOTS.DASHBOARD}/callbot`,
      settings: (id: string) => `${ROOTS.DASHBOARD}/callbot/${id}/settings`,
      functions: {
        root: (id: string) => `${ROOTS.DASHBOARD}/callbot/${id}/functions`,
        new: (id: string) => `${ROOTS.DASHBOARD}/callbot/${id}/functions/new`,
        edit: (callbotId: string, functionId: string) =>
          `${ROOTS.DASHBOARD}/callbot/${callbotId}/functions/${functionId}/edit`,
      },
    },
    team: {
      root: `${ROOTS.DASHBOARD}/teams`,
      new: `${ROOTS.DASHBOARD}/teams/new`,
      list: `${ROOTS.DASHBOARD}/teams/list`,
    },
    call: {
      root: `${ROOTS.DASHBOARD}/calls`,
      new: `${ROOTS.DASHBOARD}/calls/new`,
      list: `${ROOTS.DASHBOARD}/calls/list`,
    },
    lead: {
      root: `${ROOTS.DASHBOARD}/leads`,
      new: `${ROOTS.DASHBOARD}/leads/new`,
      list: `${ROOTS.DASHBOARD}/leads/list`,
    },
  },
};
