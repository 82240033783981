// routes/sections/main.tsx

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// Error
const Page404 = lazy(() => import('src/pages/error/404'));
const SuccessPage = lazy(() => import('src/pages/success/index'));

// ----------------------------------------------------------------------

const MainRoutes = () => {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  );
};

const mainRoutes = [
  {
    path: '/',
    element: <MainRoutes />,
    children: [
      { path: '404', element: <Page404 /> },
      { path: 'success', element: <SuccessPage /> },
    ],
  },
];

export { mainRoutes };
