import { paths } from 'src/routes/paths';
import { CONFIG } from 'src/config-global';
import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { SvgColor } from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`${CONFIG.site.basePath}/assets/icons/navbar/${name}.svg`} />
);

const ICONS = {
  job: icon('ic-job'),
  blog: icon('ic-blog'),
  chat: icon('ic-chat'),
  mail: icon('ic-mail'),
  user: icon('ic-user'),
  file: icon('ic-file'),
  lock: icon('ic-lock'),
  tour: icon('ic-tour'),
  order: icon('ic-order'),
  label: icon('ic-label'),
  blank: icon('ic-blank'),
  kanban: icon('ic-kanban'),
  folder: icon('ic-folder'),
  course: icon('ic-course'),
  banking: icon('ic-banking'),
  booking: icon('ic-booking'),
  invoice: icon('ic-invoice'),
  product: icon('ic-product'),
  calendar: icon('ic-calendar'),
  disabled: icon('ic-disabled'),
  external: icon('ic-external'),
  menuItem: icon('ic-menu-item'),
  ecommerce: icon('ic-ecommerce'),
  analytics: icon('ic-analytics'),
  dashboard: icon('ic-dashboard'),
  parameter: icon('ic-parameter'),
  team: icon('ic-team'),
  contacts: icon('ic-contacts'),
  calls: icon('ic-calls'),
  campaigns: icon('ic-campaigns'),
  callbots: icon('ic-callbots'),
  leads: icon('ic-leads'),
};

// ----------------------------------------------------------------------

export const navData = [
  /**
   * Overview
   */
  {
    subheader: 'Overview',
    items: [

      { title: 'Call', path: paths.dashboard.general.course, icon: ICONS.course },
       { title: 'Campaign', path: paths.dashboard.general.course, icon: ICONS.analytics },
       { title: 'Calendar', path: paths.dashboard.calendar, icon: ICONS.calendar },
    ],
  },
  /**
   * Management
   */
  {
    subheader: 'Management',
    items: [
      {
        title: 'User',
        path: paths.dashboard.user.root,
        icon: ICONS.user,
        children: [
          { title: 'List', path: paths.dashboard.user.list },
          { title: 'Create', path: paths.dashboard.user.new },
          { title: 'Team', path: paths.dashboard.user.demo.edit },
        ],
      },
      {
        title: 'Contact',
        path: paths.dashboard.contact.root,
        icon: ICONS.kanban,
        children: [
          { title: 'List', path: paths.dashboard.contact.root },
          { title: 'Group', path: paths.dashboard.contact.new },
          //{ title: 'Edit', path: paths.dashboard.contacts.demo.edit },
        ],
      },
      {
        title: 'Callbot',
        path: paths.dashboard.callbot.root,
        icon: ICONS.chat,
        children: [
          { title: 'Settings', path: paths.dashboard.callbot.settings('some-callbot-id') },
          {
            title: 'Functions',
            path: paths.dashboard.callbot.functions.root('some-callbot-id'),
          },
        ],
      },
      {
        title: 'Campaign',
        path: paths.dashboard.campaign.root,
        icon: ICONS.kanban,
        children: [
          { title: 'List', path: paths.dashboard.campaign.root },
          { title: 'Create', path: paths.dashboard.campaign.new },
          { title: 'Stats', path: paths.dashboard.campaign.root },
          { title: 'settings', path: paths.dashboard.campaign.root },
        ],
      },
      {
        title: 'Lead',
        path: paths.dashboard.lead.root,
        icon: ICONS.kanban,
        children: [
          { title: 'List', path: paths.dashboard.lead.root },
          { title: 'Create', path: paths.dashboard.lead.new },
          { title: 'settings', path: paths.dashboard.lead.root },
        ],
      },
    ],
  },
 ];