// src/utils/storage.ts
import { UserType } from '../../types';

export const USER_STORAGE_KEY : string = 'user';

export const saveUserToLocalStorage = (user: UserType): void => {
    if (user) {
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    } else {
        localStorage.removeItem(USER_STORAGE_KEY);
    }
};

export const getUserFromLocalStorage = (): UserType => {
    const userJson = localStorage.getItem(USER_STORAGE_KEY);
    return userJson ? JSON.parse(userJson) as UserType : null;
};
