import axios, { AxiosRequestConfig } from "axios";
import { CONFIG } from "src/config-global";
import log from "src/utils/logger";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: CONFIG.site.serverUrl,
  withCredentials: true, // Assurez-vous que cette ligne est activée
});

axiosInstance.interceptors.response.use(
  (response) => {
    log.debug("Response:", response);
    return response;
  },
  (error) => {
    log.error("Response Error:", error);
    return Promise.reject((error.response && error.response.data) || "Something went wrong!");
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  try {
    const [url, config] = Array.isArray(args) ? args : [args];
    const res = await axiosInstance.get(url, { ...config });
    log.debug("Fetcher Response:", res.data);
    return res.data;
  } catch (error) {
    log.error("Fetcher Error:", error);
    throw error;
  }
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: "/api/chat",
  kanban: "/api/kanban",
  calendar: "/api/calendar",
  auth: {
    me: "/api/auth/me",
    signIn: "/api/auth/sign-in",
    signUp: "/api/auth/sign-up",
  },
  mail: {
    list: "/api/mail/list",
    details: "/api/mail/details",
    labels: "/api/mail/labels",
  },
  post: {
    list: "/api/post/list",
    details: "/api/post/details",
    latest: "/api/post/latest",
    search: "/api/post/search",
  },
  product: {
    list: "/api/product/list",
    details: "/api/product/details",
    search: "/api/product/search",
  },

  // Endpoints de l'api Avalanches
  user: "/v1/users",
  callbot: '/v1/callbots',
  customFunctions: "/v1/custom_functions", // Ajoutez ceci pour les fonctions personnalisées

};
