import { useState, useEffect } from "react";
import { useRouter, useSearchParams } from "src/routes/hooks";
import { CONFIG } from "src/config-global";
import { SplashScreen } from "src/components/loading-screen";
import { useAuthContext } from "../hooks";
import log from "loglevel";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function GuestGuard({ children }: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { loading, authenticated } = useAuthContext();
  const [isChecking, setIsChecking] = useState<boolean>(true);

  const returnTo = searchParams.get("returnTo") || CONFIG.auth.redirectPath;

  log.debug("GuestGuard mounted");
  log.debug("GuestGuard: loading:", loading);
  log.debug("GuestGuard: authenticated:", authenticated);
  log.debug("GuestGuard: returnTo:", returnTo);

  const checkPermissions = async (): Promise<void> => {
    if (loading) {
      log.debug("GuestGuard: still loading...");
      return;
    }

    if (authenticated) {
      log.debug("GuestGuard: user is authenticated, redirecting to:", "/dashboard");
      router.replace("/dashboard");
      return;
    }

    log.debug("GuestGuard: user is not authenticated, rendering children");
    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, loading]);

  if (isChecking) {
    log.debug("GuestGuard: checking permissions...");
    return <SplashScreen />;
  }

  return <>{children}</>;
}
