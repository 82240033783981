import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { DashboardLayout } from "src/layouts/dashboard";

import { LoadingScreen } from "src/components/loading-screen";

import { AuthGuard } from "src/auth/guard";

// ----------------------------------------------------------------------

// Overview
const IndexPage = lazy(() => import("src/pages/dashboard"));
const OverviewCoursePage = lazy(() => import("src/pages/dashboard/course"));

// User
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"));

// CallBot
const CallBotEditPage = lazy(() => import("src/pages/dashboard/callbot/edit"));
const CallBotSettingsPage = lazy(
  () => import("src/pages/dashboard/callbot/settings")
);

// Custom Function
const CustomFunctionListPage = lazy(
  () => import("src/pages/dashboard/custom-function/list")
);
const CustomFunctionCreatePage = lazy(
  () => import("src/pages/dashboard/custom-function/new")
);
const CustomFunctionEditPage = lazy(
  () => import("src/pages/dashboard/custom-function/edit")
);

// App
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));

// ----------------------------------------------------------------------

const layoutContent = (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </DashboardLayout>
);

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: <AuthGuard>{layoutContent}</AuthGuard>,
    children: [
      { element: <IndexPage />, index: true },
      { path: "course", element: <OverviewCoursePage /> },
      {
        path: "user",
        children: [
          { element: <UserListPage />, index: true },
          { path: "list", element: <UserListPage /> },
          { path: "new", element: <UserCreatePage /> },
          { path: ":id/edit", element: <UserEditPage /> },
          { path: "account", element: <UserAccountPage /> },
        ],
      },
      {
        path: "callbot",
        children: [
          { path: ":id/edit", element: <CallBotEditPage /> },
          { path: ":id/settings", element: <CallBotSettingsPage /> },
          {
            path: ":id/functions",
            children: [
              { element: <CustomFunctionListPage />, index: true },
              { path: "new", element: <CustomFunctionCreatePage /> },
              { path: ":functionId/edit", element: <CustomFunctionEditPage /> },
            ],
          },
        ],
      },
      { path: "calendar", element: <CalendarPage /> },
    ],
  },
];
