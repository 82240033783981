import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard, GuestGuard } from "src/auth/guard";
import { SplashScreen } from "src/components/loading-screen";
import { AuthCenteredLayout } from "src/layouts/auth/layout";

/** **************************************
 * Centered layout
 *************************************** */
const CenteredLayout = {
  MicrosoftOAuth: lazy(() => import("src/pages/auth/outlook/signOAuthOutlook")),
  SignInPage: lazy(() => import("src/pages/auth/jwt/sign-in")),
  SignUpPage: lazy(() => import("src/pages/auth/jwt/sign-up")),
  VerifyPage: lazy(() => import("src/pages/auth/jwt/verify")),
  ResetPasswordPage: lazy(() => import("src/pages/auth/jwt/reset-password")),
  UpdatePasswordPage: lazy(() => import("src/pages/auth/jwt/update-password")),
};

const Oauth = {
  path: "oauth",
  element: <Outlet />,
  children: [
    {
      path: "microsoft",
      element: (
        <AuthGuard>
          <CenteredLayout.MicrosoftOAuth />
        </AuthGuard>
      ),
    },
  ],
};

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: "auth",
    element: (
      <Suspense fallback={<SplashScreen />}>
        <AuthCenteredLayout>
          <Outlet />
        </AuthCenteredLayout>
      </Suspense>
    ),
    children: [
      Oauth,
      {
        path: "sign-in",
        element: (
          <GuestGuard>
            <CenteredLayout.SignInPage />
          </GuestGuard>
        ),
      },
      {
        path: "sign-up",
        element: (
          <GuestGuard>
            <CenteredLayout.SignUpPage />
          </GuestGuard>
        ),
      },
      {
        path: "verify",
        element: (
          <GuestGuard>
            <CenteredLayout.VerifyPage />
          </GuestGuard>
        ),
      },
      {
        path: "reset-password",
        element: (
          <GuestGuard>
            <CenteredLayout.ResetPasswordPage />
          </GuestGuard>
        ),
      },
      {
        path: "update-password",
        element: (
          <GuestGuard>
            <CenteredLayout.UpdatePasswordPage />
          </GuestGuard>
        ),
      },
    ],
  },
];
